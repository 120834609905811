/* Log List, Vue Component */
<template>
  <v-card id="log-list">
    <v-card-title>Application Log</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">


      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>



        <v-select
          v-model="filter.Level"
          :items="filterItems.Level"
          single-line
          outlined
          dense
          hide-details
          clearable
          multiple
          placeholder="Filter Log Level"
          class="mr-4"
        ></v-select>



      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Created`]="{item}">
      <!-- {{ Display.DateTime(item.Created) }} -->
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'log-view', params: { id: item.Id } }">
      {{ item.Created }}
      </router-link>
    </template>

    <template #[`item.Detail`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.Detail }}</div>
        </template>
        <span>{{ item.Detail }}</span>
      </v-tooltip>
    </template>

    <!-- <template #[`item.Name`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'log-view', params: { id: item.Id } }">
      {{ item.Name }}
    </router-link>
    </template> -->

    <!-- <template #[`item.Summary`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'log-view', params: { id: item.Id } }">
      {{ item.Summary }}
      </router-link>
    </template> -->

    <template #[`item.Level`]="{item}">
      <v-icon size="16" :color="levels[item.Level].color">{{ levels[item.Level].icon }}</v-icon>
      {{ levels[item.Level].name }}
    </template>




    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiAlertDecagram,
  mdiAlertCircle,
  mdiAlert,
  mdiInformation,
  mdiHelpCircle,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'


export default {
  components: {
  },
  setup() {

  // define cols
  const tableColumns = [
    { text: 'Created', value: 'Created' },
    { text: 'Log Level', value: 'Level' },
		{ text: 'Event', value: 'Summary' },
    { text: 'Info', value: 'Detail' },
    { text: 'Location', value: 'Name' },
		{
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({});
  const options = ref({
    sortBy: ['Created'],
    sortDesc: [true],
    itemsPerPage: 100
  });
  const footerProps = {
    'items-per-page-options': [50, 100, 250, 500, -1]
  };

  // items
  const items = computed(() => store.state.app.Logs || []);
  const totalItems = computed(() => store.state.app.LogsTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchLogs', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


    const levels = {
      1: {icon: mdiAlertDecagram, name: "Critical", color: "error"},
      2: {icon: mdiAlertCircle, name: "Error", color: "error"},
      3: {icon: mdiAlert, name: "Warning", color: "warning"},
      4: {icon: mdiInformation, name: "Info", color: "info"},
      5: {icon: mdiHelpCircle, name: "Debug", color: "success"}
    }

  // data for filter fields
  const filterItems = {};
  filterItems.Level = Object.keys(levels).map((key) => {
    let level = levels[key];
    return { text: level.name, value: key };
  });
  console.dir(levels);

    return {
      levels,
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,

      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
