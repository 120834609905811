var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"log-list"}},[_c('v-card-title',[_vm._v("Application Log")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search Fields"},model:{value:(_vm.search.all),callback:function ($$v) {_vm.$set(_vm.search, "all", $$v)},expression:"search.all"}}),_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.filterItems.Level,"single-line":"","outlined":"","dense":"","hide-details":"","clearable":"","multiple":"","placeholder":"Filter Log Level"},model:{value:(_vm.filter.Level),callback:function ($$v) {_vm.$set(_vm.filter, "Level", $$v)},expression:"filter.Level"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Created",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'log-view', params: { id: item.Id } }}},[_vm._v(" "+_vm._s(item.Created)+" ")])]}},{key:"item.Detail",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-overflow-ellipses",staticStyle:{"width":"180px"}},'div',attrs,false),on),[_vm._v(_vm._s(item.Detail))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Detail))])])]}},{key:"item.Level",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"16","color":_vm.levels[item.Level].color}},[_vm._v(_vm._s(_vm.levels[item.Level].icon))]),_vm._v(" "+_vm._s(_vm.levels[item.Level].name)+" ")]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }